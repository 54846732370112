// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <a-spin :spinning="loading">
    <div v-if="remoteAccessVpn">
      <div>
        <p>{{ $t('message.enabled.vpn') }} <strong><a target="_blank" :href="'https://' + remoteAccessVpn.publichostname + ':' + remoteAccessVpn.publicport" >https://{{ remoteAccessVpn.publichostname }}:{{ remoteAccessVpn.publicport }}</a></strong></p>
        <p style="display: none">{{ $t('message.enabled.vpn.ip.sec') }} <strong>{{ remoteAccessVpn.presharedkey }}</strong></p>

        <a-divider/>
        <div>
          <h4 style="display: inline-block; margin: 2px">{{ $t('label.static.routes') }}</h4>
          <a-button
            type="primary"
            @click="addRoute = true">
            <plus-outlined />
          </a-button>
        </div>
        <br/>
        <a-list size="large" class="list">
          <a-list-item :key="index" v-for="(route, index) in routes" class="item">
            <a-list-item-meta>
              <template #title>
                  {{ route.value }} <span style="float: right">{{ route.title }}</span>
              </template>
            </a-list-item-meta>
            <template #actions class="action">
              <a-button
                shape="circle"
                type="danger"
                @click="this.deleteRoute(route)"
                v-if="!route.default">
                <delete-outlined />
              </a-button>
            </template>
          </a-list-item>
        </a-list>

        <a-divider/>
        <a-button><router-link :to="{ path: '/vpnuser'}">{{ $t('label.manage.vpn.user') }}</router-link></a-button>
        <a-button
          style="margin-left: 10px"
          @click="updateRemoteAccessVpn"
          :disabled="!('updateRemoteAccessVpn' in $store.getters.apis)">
          {{ $t('label.update.static.routes') }}
        </a-button>
        <a-button
          style="margin-left: 10px"
          type="primary"
          danger
          @click="disableVpn = true"
          :disabled="!('deleteRemoteAccessVpn' in $store.getters.apis)">
          {{ $t('label.disable.vpn') }}
        </a-button>
      </div>

      <a-modal
        :visible="disableVpn"
        :footer="null"
        :title="$t('label.disable.vpn')"
        :closable="true"
        :maskClosable="false"
        @cancel="disableVpn = false">
        <div v-ctrl-enter="handleDisableVpn">
          <p>{{ $t('message.disable.vpn') }}</p>

          <a-divider />

          <div class="actions">
            <a-button @click="() => disableVpn = false">{{ $t('label.cancel') }}</a-button>
            <a-button type="primary" @click="handleDisableVpn">{{ $t('label.yes') }}</a-button>
          </div>
        </div>
      </a-modal>

      <a-modal
        :visible="addRoute"
        :footer="null"
        :title="$t('label.add.static.route')"
        :maskClosable="false"
        :closable="true"
        @cancel="addRoute = false">
        <div v-ctrl-enter="handleAddRoute">
          <a-input
            v-focus="true"
            v-model:value="routeValue"
            :placeholder="$t('label.cidr')">
          </a-input>
          <a-divider />
          <div class="actions">
            <a-button @click="() => addRoute = false">{{ $t('label.cancel') }}</a-button>
            <a-button type="primary" ref="submit" @click="handleAddRoute">{{ $t('label.yes') }}</a-button>
          </div>
        </div>
      </a-modal>
    </div>
    <div v-else>
      <div v-if="showIpDetails">
        <p>{{ $t('label.public.ip') }} <strong>{{ resource.ipaddress }}</strong></p>
      </div>
      <a-button :disabled="!('createRemoteAccessVpn' in $store.getters.apis)" type="primary" @click="enableVpn = true">
        {{ $t('label.enable.vpn') }}
      </a-button>

      <a-modal
        :visible="enableVpn"
        :footer="null"
        :title="$t('label.enable.vpn')"
        :maskClosable="false"
        :closable="true"
        @cancel="enableVpn = false">
        <div v-ctrl-enter="handleCreateVpn">
          <p>{{ $t('message.enable.vpn') }}</p>

          <a-divider />
          <p>{{ $t('label.ip.range') }}</p>
          <a-input-group class="form__item__input-container" compact>
            <a-input
              v-focus="true"
              v-model:value="newvpn.iprangestart"
              :placeholder="$t('label.start.ip')"
              style="border-right: 0; width: calc(50% - 15px); margin-right: 0;"></a-input>
            <a-input
              placeholder="-"
              disabled
              class="tag-disabled-input"
              style="width: 30px; border-left: 0; border-right: 0; pointer-events: none; text-align:
              center; margin-right: 0;"></a-input>
            <a-input
              v-model:value="newvpn.iprangeend"
              :placeholder="$t('label.end.ip')"
              style="border-left: 0; width: calc(50% - 15px); text-align: right; margin-right: 0;"></a-input>
          </a-input-group>
          <a-divider />
          <div class="actions">
            <a-button @click="() => enableVpn = false">{{ $t('label.cancel') }}</a-button>
            <a-button type="primary" ref="submit" @click="handleCreateVpn">{{ $t('label.yes') }}</a-button>
          </div>
        </div>
      </a-modal>

    </div>
  </a-spin>
</template>

<script>
import { api } from '@/api'

export default {
  props: {
    showIpDetails: {
      type: Boolean,
      required: false
    },
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      remoteAccessVpn: null,
      enableVpn: false,
      disableVpn: false,
      isSubmitted: false,
      newvpn: {
        iprangestart: '192.168.254.1',
        iprangeend: '192.168.254.254'
      },
      routes: [],
      newRoutes: [],
      addRoute: false,
      routeValue: ''
    }
  },
  inject: ['parentFetchData', 'parentToggleLoading'],
  created () {
    this.fetchData()
  },
  watch: {
    resource: {
      deep: true,
      handler (newItem) {
        if (!newItem || !newItem.id) {
          return
        }
        this.fetchData()
      }
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      api('listRemoteAccessVpns', {
        publicipid: this.resource.id,
        listAll: true
      }).then(response => {
        this.remoteAccessVpn = response.listremoteaccessvpnsresponse.remoteaccessvpn
          ? response.listremoteaccessvpnsresponse.remoteaccessvpn[0] : null
        this.loading = false
        this.fetchDetails()
      }).catch(error => {
        console.log(error)
        this.$notifyError(error)
        this.loading = false
      })
    },
    handleCreateVpn () {
      if (this.isSubmitted) return
      this.isSubmitted = true
      this.loading = true
      this.enableVpn = false
      api('createRemoteAccessVpn', {
        publicipid: this.resource.id,
        domainid: this.resource.domainid,
        account: this.resource.account,
        iprange: this.newvpn.iprangestart + '-' + this.newvpn.iprangeend
      }).then(response => {
        this.$pollJob({
          jobId: response.createremoteaccessvpnresponse.jobid,
          successMethod: result => {
            const res = result.jobresult.remoteaccessvpn
            this.$notification.success({
              message: this.$t('label.status'),
              description:
                `${this.$t('message.enabled.vpn')} ${'https://' + res.publichostname + ':' + res.publicport}.`,
              duration: 0
            })
            this.remoteAccessVpn = res
            this.fetchData()
            this.isSubmitted = false
          },
          errorMessage: this.$t('message.enable.vpn.failed'),
          errorMethod: () => {
            this.fetchData()
            this.isSubmitted = false
          },
          loadingMessage: this.$t('message.enable.vpn.processing'),
          catchMessage: this.$t('error.fetching.async.job.result'),
          catchMethod: () => {
            this.fetchData()
            this.isSubmitted = false
          }
        })
      }).catch(error => {
        this.$notifyError(error)
        this.fetchData()
        this.isSubmitted = false
      })
    },
    handleDisableVpn () {
      if (this.isSubmitted) return
      this.isSubmitted = true
      this.loading = true
      this.disableVpn = false
      api('deleteRemoteAccessVpn', {
        publicipid: this.resource.id,
        domainid: this.resource.domainid
      }).then(response => {
        this.$pollJob({
          jobId: response.deleteremoteaccessvpnresponse.jobid,
          successMessage: this.$t('message.success.disable.vpn'),
          successMethod: () => {
            this.fetchData()
            this.isSubmitted = false
          },
          errorMessage: this.$t('message.disable.vpn.failed'),
          errorMethod: () => {
            this.fetchData()
            this.isSubmitted = false
          },
          loadingMessage: this.$t('message.disable.vpn.processing'),
          catchMessage: this.$t('error.fetching.async.job.result'),
          catchMethod: () => {
            this.fetchData()
            this.isSubmitted = false
          }
        })
      }).catch(error => {
        this.$notifyError(error)
        this.fetchData()
        this.isSubmitted = false
      })
    },
    populateRoute (cidr, title, automatic) {
      this.newRoutes.push({ value: cidr, default: !!automatic, title })
    },
    fetchBaseRoutes (callback) {
      if (this.resource.vpcid) {
        api('listVPCs', { id: this.resource.vpcid, listall: true }).then(response => {
          const vpc = response?.listvpcsresponse?.vpc?.[0]
          if (vpc) {
            this.populateRoute(vpc.cidr, 'vpc', true)
          }
        }).catch(error => {
          console.error(error)
          this.$message.error(this.$t('message.error.loading.setting'))
        }).finally(() => {
          if (!callback) return
          callback()
        })
      } else {
        if (this.resource.networkid) {
          api('listNetworks', { id: this.resource.networkid, listall: true }).then(response => {
            const network = response.listnetworksresponse?.network?.[0]
            if (network) {
              this.populateRoute(network.cidr, 'network', true)
            }
          }).catch(error => {
            console.error(error)
            this.$message.error(this.$t('message.error.loading.setting'))
          }).finally(() => {
            if (!callback) return
            callback()
          })
        }
      }
    },
    fetchSiteToSiteRoutes (callback) {
      if (this.resource.vpcid) {
        api('listVpnConnections', { vpcid: this.resource.vpcid, listall: true }).then(response => {
          const connections = response?.listvpnconnectionsresponse?.vpnconnection
          if (connections) {
            for (var i = 0; i < connections.length; i++) {
              var connection = connections[i]
              var cidrs = connection.cidrlist.split(',')
              for (var j = 0; j < cidrs.length; j++) {
                var cidr = cidrs[j]
                this.populateRoute(cidr, 'site to site', true)
              }
            }
          }
        }).catch(error => {
          console.error(error)
          this.$message.error(this.$t('message.error.loading.setting'))
        }).finally(() => {
          if (!callback) return
          callback()
        })
      }
    },
    fetchCustomRoutes (callback) {
      const params = {
        resourceId: this.remoteAccessVpn.id,
        resourceType: 'RemoteAccessVpn',
        list: 'all',
        key: 'routes'
      }
      api('listResourceDetails', params).then(response => {
        var item = response?.listresourcedetailsresponse?.resourcedetail?.[0]
        if (item) {
          var cidrs = item.value.split(',')
          for (var j = 0; j < cidrs.length; j++) {
            var cidr = cidrs[j]
            this.populateRoute(cidr, '', false)
          }
        }
      }).catch(error => {
        console.error(error)
        this.$message.error(this.$t('message.error.loading.setting'))
      }).finally(() => {
        if (!callback) return
        callback()
      })
    },
    fetchDetails (callback) {
      if (this.remoteAccessVpn) {
        this.loading = true
        this.newRoutes = []
        this.fetchBaseRoutes(() => {
          this.fetchSiteToSiteRoutes(() => {
            this.fetchCustomRoutes(() => {
              this.loading = false
              this.routes = this.newRoutes
            })
          })
        })
      }
    },
    updateRemoteAccessVpn () {
      this.loading = true
      api('updateRemoteAccessVpn', { id: this.remoteAccessVpn.id })
        .then(response => {
          this.$pollJob({
            jobId: response.updateremoteaccessvpnresponse.jobid,
            title: this.$t('label.updating.static.routes'),
            description: this.remoteAccessVpn.id,
            successMethod: () => { }
          })
        })
        .catch(error => { console.error(error) })
        .finally(() => { this.loading = false })
    },
    isCidr (value) {
      var cidrparts = value.split('/')
      if (cidrparts.length !== 2) {
        return false
      }
      var parts = cidrparts[0].split('.')
      var ip = []
      for (var i = 0; i < parts.length; i++) {
        var subnet = parts[i]
        if (subnet) {
          for (var j = 0; j < subnet.length; j++) {
            if (!(subnet[j] >= '0' && subnet[j] <= '9')) {
              return false
            }
          }
          var ipval = parseInt(parts[i])
          if (ipval >= 0 && ipval <= 255) {
            ip.push(ipval)
          } else {
            return false
          }
        }
      }
      if (ip.length < 4) {
        return false
      }
      var mask = cidrparts[1] || ''
      for (var k = 0; k < mask.length; k++) {
        if (!(mask[k] >= '0' && mask[k] <= '9')) {
          return false
        }
      }
      var maskInt = parseInt(mask)
      if (maskInt > 32) {
        return false
      }
      return true
    },
    deleteRoute (route) {
      const params = {
        resourceId: this.remoteAccessVpn.id,
        resourceType: 'RemoteAccessVpn',
        list: 'all',
        key: 'routes'
      }
      this.loading = true
      api('listResourceDetails', params).then(response => {
        var item = response?.listresourcedetailsresponse?.resourcedetail?.[0]
        var found = false
        if (item) {
          var cidrs = item.value.split(',')
          for (var j = 0; j < cidrs.length; j++) {
            var cidr = cidrs[j]
            if (cidr === route.value) {
              found = true
            }
          }
          if (found) {
            var newRoutes = item.value.split(',').filter(p => p !== route.value).join(',')
            this.loading = true
            api('removeResourceDetail', params).then(() => {
              this.loading = true
              api('addResourceDetail', {
                resourceId: this.remoteAccessVpn.id,
                resourceType: 'RemoteAccessVpn',
                'details[0].key': 'routes',
                'details[0].value': newRoutes
              }).finally(() => {
                this.loading = false
                this.fetchDetails()
              })
            }).finally(() => {
              this.loading = false
            })
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleAddRoute () {
      var route = this.routeValue
      this.routeValue = ''
      this.addRoute = false
      if (route.indexOf('/') === -1) {
        route = route + '/32'
      }
      if (!this.isCidr(route)) {
        return
      }
      const params = {
        resourceId: this.remoteAccessVpn.id,
        resourceType: 'RemoteAccessVpn',
        list: 'all',
        key: 'routes'
      }
      this.loading = true
      api('listResourceDetails', params).then(response => {
        var item = response?.listresourcedetailsresponse?.resourcedetail?.[0]
        var newRoutes = ''
        var found = false
        if (item) {
          newRoutes = item.value
          var cidrs = newRoutes.split(',')
          for (var j = 0; j < cidrs.length; j++) {
            var cidr = cidrs[j]
            if (cidr === route) {
              found = true
            }
          }
        }
        if (!found) {
          if (newRoutes) newRoutes = newRoutes + ',' + route
          else newRoutes = route
          this.loading = true
          api('removeResourceDetail', params).then(() => {
            this.loading = true
            api('addResourceDetail', {
              resourceId: this.remoteAccessVpn.id,
              resourceType: 'RemoteAccessVpn',
              'details[0].key': 'routes',
              'details[0].value': newRoutes
            }).finally(() => {
              this.loading = false
              this.fetchDetails()
            })
          }).finally(() => {
            this.loading = false
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .actions {
    display: flex;
    justify-content: flex-end;

    button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
</style>
